import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, of } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { SpinSetsService } from 'src/app/services/spin-sets.service';
import { SpinSet } from 'src/app/models/spin-set';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { PaymentService } from 'src/app/services/payment.service';
import { PwaService } from 'src/app/services/pwa.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  trigger!: MatMenuTrigger;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  spinSets: SpinSet[] = [];
  allowAdd: boolean = false;
  status: boolean = false;
  spinnerCount: number = 0;
  limit: number = 0;
  //showUpgrade!: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private spinSetsService: SpinSetsService,
    private router: Router,
    public paymentService: PaymentService,
    public pwa: PwaService,
  ) {

  }

  ngOnInit() {
    //this.showUpgrade = this.paymentService.showUpgrade;

    this.spinSetsService.spinSets.subscribe(result => {
      this.spinSets = result;
    })
    this.spinSetsService.getSpinSets();

    this.updateStatus();

    this.paymentService.userUpgraded.subscribe(result => {
      if (result) {
        this.paymentService.checkStatus().pipe(
          concatMap(result => {
            this.limit = result.limit;
            return this.spinSetsService.countSpinners();
          }),
          concatMap(result => {
            this.spinnerCount = result;
            return of(this.spinnerCount < this.limit);
          })
        ).subscribe(result => {
          console.log('check status user upgrade');
          this.allowAdd = result;
        });
      }
    });

    this.paymentService.showUpgrade.subscribe(result => {
      this.allowAdd = !result;
    });
  }

  updateStatus() {
    this.paymentService.checkStatus().pipe(
      concatMap(result => {
        this.limit = result.limit;
        return this.spinSetsService.countSpinners();
      }),
      concatMap(result => {
        this.spinnerCount = result;
        return of(this.spinnerCount < this.limit);
      })).subscribe(result => {
        this.allowAdd = result;
      });
  }

  getSpinner(id: string) {
    this.spinSetsService.getSpinner(id);
    this.router.navigateByUrl('/main/dashboard');
    //console.log(this.trigger);
    //this.trigger.closeMenu();
  }

  editSpinner(id: string) {
    this.spinSetsService.currentSpinner = id;
    this.spinSetsService.getSpinner(id);
    this.router.navigateByUrl('main/edit-set');
  }

  deleteSpinner(id: string) {
    this.spinSetsService.deleteSpinSet(id);
  }

  installPwa(): void {
    this.pwa.promptEvent.prompt();
  }

  logout() {
    this.authService.signOut();
  }

}
