import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { loadStripe } from '@stripe/stripe-js';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  statusSubject: Subject<boolean> = new Subject<boolean>();
  status: Observable<boolean>;
  statusStatic: boolean = false;
  limit: number = 2;
  showUpgradeSubject: Subject<boolean> = new Subject<boolean>();
  showUpgrade: Observable<boolean>;
  userUpgradedSubject: Subject<boolean> = new Subject<boolean>();
  userUpgraded: Observable<boolean>;

  constructor(
    private fns: AngularFireFunctions,
  ) { 
    this.status = this.statusSubject.asObservable();
    //this.showUpgradeSubject.next(true);
    this.showUpgrade = this.showUpgradeSubject.asObservable();
    this.userUpgraded = this.userUpgradedSubject.asObservable();
  }

  async getIntent() {
    const stripe = await loadStripe(environment.stripeKey);
    const callable = this.fns.httpsCallable('getPaymentIntent');
    return callable({amount: 200});
  }

  checkUpgrade(id: string) {
    const callable = this.fns.httpsCallable('verifyPayment');
    return callable({id: id});
  }

  checkStatus() {
    const callable = this.fns.httpsCallable('userStatus');
    return callable({}).pipe(map(result => {
      //console.log('check status');
      //console.log(result);
      this.statusSubject.next(result.paid);
      this.statusStatic = result.paid;
      this.showUpgradeSubject.next(!result.paid);
      return result;
    }));
  }
}
