<mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content class="flex-column">
      <mat-toolbar color="primary" class="flex-header">
        <span>raffleSPIN</span>
      </mat-toolbar>
      <div class="flex-content">
        <router-outlet></router-outlet>
      </div>
      <div class="flex-footer">
  
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>