// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCEPBSK45XzMTR1cJHy6hN8ZmALP0CYim4",
    authDomain: "rafflespin.firebaseapp.com",
    projectId: "rafflespin",
    storageBucket: "rafflespin.appspot.com",
    messagingSenderId: "308253755147",
    appId: "1:308253755147:web:29c6906fb3290bbfc9440e",
    measurementId: "G-PDSV3P6W0M"
  },
  stripeKey: 'pk_test_51IUHLyLu3IkDzvOhaeuB7OQFsHuSbn3uIPNUMYwZCj4kMH3hulY8qYArP6CFrAEZVpRZlmeC61k7zcaUVH0sI5Z200dly8KOV1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.