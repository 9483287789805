import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import auth from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User | null | undefined>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) { 
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  async googleSignin() {
    const provider = new auth.auth.GoogleAuthProvider();
    const persist = await this.afAuth.setPersistence('local');
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async emailSignin(email: string, password: string) {
    const persist = await this.afAuth.setPersistence('local');
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async emailSignUp(email: string, password: string) {
    const persist = await this.afAuth.setPersistence('local');
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  updateUserData(user: any, name: string = '') {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    console.log(user);
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName ?? name,
      photoURL: user.photoURL
    };

    return userRef.set(data, {merge: true});
  }

  async signOut() {
    await this.afAuth.signOut();
    this.router.navigateByUrl('/auth/login');
  }

  currentUser(): User {
    const user = auth.auth().currentUser;
    //console.log(user);
    var current: User = {
      uid: '',
      displayName: '',
      email: '',
    }

    if (user != null) {
      current.uid = user.uid;
      current.displayName = user.displayName ?? undefined;
      current.email = user.email ?? '';
    }

    return current;
  }
}
