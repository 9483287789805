import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { SpinData } from '../models/spin-data';
import { SpinSet } from '../models/spin-set';
import { WheelOption } from '../models/wheel-options';
import { AuthService } from './auth.service';
import { PaymentService } from './payment.service';

@Injectable({
  providedIn: 'root'
})
export class SpinSetsService {
  uid: string;
  wheelSubject: Subject<WheelOption[]> = new Subject<WheelOption[]>();
  wheelOptions: Observable<WheelOption[]>;
  spinSetsSubject: Subject<SpinSet[]> = new Subject<SpinSet[]>();
  spinSets: Observable<SpinSet[]>;
  winnerSubject: Subject<string> = new Subject<string>();
  winner: Observable<string>;
  titleSubject: Subject<string> = new Subject<string>();
  title: Observable<string>;
  currentSpinner: string = '';
  countSubject: Subject<number> = new Subject<number>();
  spinnerCount: Observable<number>;

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private fns: AngularFireFunctions,
    private paymentService: PaymentService,
  ) {
    this.uid = '';
    this.wheelOptions = this.wheelSubject.asObservable();
    this.spinSets = this.spinSetsSubject.asObservable();
    this.winner = this.winnerSubject.asObservable();
    this.title = this.titleSubject.asObservable();
    this.spinnerCount = this.countSubject.asObservable();
  }

  getSpinSets() {
    this.uid = this.authService.currentUser().uid;
    let docRef = this.firestore.collection('spinners').doc(this.uid).collection('sets');
    return docRef.ref.get().then(result => {
      //console.log('docs ' + result.docs[0].get('name'));
      if (result.docs.length > 0) {
        //console.log('spin set 0 ' + JSON.stringify(result.docs[0].data().name));
        let s: SpinSet[] = [];
        result.docs.forEach(element => {
          s.push({ name: element.data().name, id: element.id });
        });
        this.spinSetsSubject.next(s);
        this.currentSpinner = s[0].id;
        return this.currentSpinner;
      } else {
        //console.log('doc not found');
        let array: { name: string, array: WheelOption[] } = {
          name: 'YesNo',
          array: [
            {
              name: 'yes',
              color: '#45a35e',
              weight: 1,
              textColor: '#ffffff'
            },
            {
              name: 'no',
              color: '#c95d5d',
              weight: 1,
              textColor: '#ffffff'
            }]
        };

        this.firestore.collection('spinners').doc(this.uid).set({ name: 'sets' }, { merge: true }).then(result => {
          //console.log(result);
          this.firestore.collection('spinners').doc(this.uid).collection('sets').add(array).then(result => {
            //console.log('add set ' + result);
            this.wheelSubject.next(array.array);
            this.spinSetsSubject.next([{ name: array.name, id: result.id }]);
            this.currentSpinner = result.id;
          });
        }).catch(error => {
          console.log(error);
        });
        return this.currentSpinner;
      }
    });
  }

  getSpinner(spinSet: string) {
    //get uid
    //console.log('getSpinners  ' + spinSet);
    this.currentSpinner = spinSet;
    this.uid = this.authService.currentUser().uid;
    let docRef = this.firestore.collection('spinners').doc(this.uid).collection('sets').doc(spinSet);
    return docRef.ref.get().then(result => {
      //console.log('docs ' + result.docs[0].get('name'));
      if (result.exists) {
        //console.log('spin set exists ' + result.data());
        let spinner = result.data() ?? {};
        this.titleSubject.next(spinner.name);
        let o: WheelOption[] = [];
        spinner.array.forEach((item: WheelOption) => {
          let w: WheelOption = {
            name: item.name,
            color: item.color,
            weight: item.weight,
            textColor: item.textColor
          }
          o.push(w);
        });
        this.wheelSubject.next(o);
        //console.log('variable o ' + o.toString());
        return result.data();
      } else {
        //console.log('doc not found');
        this.getSpinSets().then(result => {
          this.getSpinner(result);
        })
        return null;
      }
    });
  }

  addSpinSet(data: SpinData) {
    //console.log('add data');
    //console.log(data);
    let status: boolean = this.paymentService.statusStatic;
    this.countSpinners().subscribe(result => {
      //console.log('add spin set count spinners');
      if ((result < 2 && !status) || (result < 50 && status)) {
        this.countSubject.next(+result + 1);
        this.firestore.collection('spinners').doc(this.uid).collection('sets').add(data).then(result => {
          console.log('add set ' + result.id);
          this.wheelSubject.next(data.array);
          this.currentSpinner = result.id;
          this.getSpinSets();

        });
      } else {
        this.getSpinSets();
        this.router.navigateByUrl('/main/dashboard');
      }
    });
  }

  updateSpinSet(data: SpinData, id: string) {
    this.firestore.collection('spinners').doc(this.uid).collection('sets').doc(id).set(data, { merge: true }).then(result => {
      //console.log('set updated ' + id);
      this.wheelSubject.next(data.array);
      this.currentSpinner = id;
      this.getSpinSets();
    });
  }

  countSpinners() {
    const callable = this.fns.httpsCallable('countSpinners');
    return callable({ id: this.uid }).pipe(map(result => {
      //console.log(result);
      this.countSubject.next(+result.count);
      return +result.count;
    }));
  }

  deleteSpinSet(id: string) {
    console.log(id);
    this.firestore.collection('spinners').doc(this.uid).collection('sets').doc(id).delete().then(result => {
      //console.log('spinner deleted');
      this.getSpinSets();
      this.countSpinners().subscribe(result => {
        this.countSubject.next(result);
      })
    });
  }
}
