<mat-sidenav-container class="sidenav-container">
  <!-- <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      mode="over"
      [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">Link 1</a>
      <a mat-list-item href="#">Link 2</a>
      <a mat-list-item (click)="logout()">Log Out</a>
    </mat-nav-list>
  </mat-sidenav> -->
  <mat-sidenav-content class="flex-column">
    <mat-toolbar color="primary" class="flex-header">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button [matMenuTriggerFor]="sets">
        <mat-icon aria-label="Side nav toggle icon">list</mat-icon>
      </button>

      <button type="button" mat-icon-button routerLink="/main/add-set" *ngIf="allowAdd">
        <mat-icon>add</mat-icon>
      </button>

      <mat-menu #sets="matMenu">
        <mat-nav-list>
          <mat-list-item *ngFor="let s of spinSets" class="flex-row">
            <button mat-menu-item (click)="getSpinner(s.id)">{{s.name}}</button>
            <button mat-menu-item style="width: 20px;" [matMenuTriggerFor]="optsMenu"
              [matMenuTriggerData]="{spinner: s}"></button>
          </mat-list-item>
        </mat-nav-list>
      </mat-menu>

      <mat-menu #optsMenu="matMenu">
        <ng-template matMenuContent let-spinner="spinner">
          <button mat-menu-item (click)="editSpinner(spinner.id)">edit</button>
          <button mat-menu-item (click)="deleteSpinner(spinner.id)">delete</button>
        </ng-template>
      </mat-menu>

      <div>
        <span style="font-size: larger;">raffleSPIN</span>
        <span *ngIf="status" style="opacity: 50%;font-size: small;">ultra</span>
      </div>
      
      <span class="flex-fill"></span>
      <button mat-icon-button [matMenuTriggerFor]="infoMenu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #infoMenu="matMenu">
        <button mat-menu-item *ngIf="pwa.promptEvent" (click)="installPwa()">
          <mat-icon>get_app</mat-icon>
          <span>install</span>
        </button>
        <button (click)="logout()" mat-menu-item>
          <mat-icon>logout</mat-icon>
          <span>logout</span>
        </button>
        <button routerLink="/user" mat-menu-item>
          <mat-icon>person</mat-icon>
          <span>profile</span>
        </button>
      </mat-menu>
    </mat-toolbar>
    <div *ngIf="(paymentService.showUpgrade | async) ? true : false" style="background-color: rgb(151, 151, 151);padding: 8px;" class="flex-row flex-vert-center">
      <span style="font-weight: 500;">upgrade to have more spinners and more colors!!!</span>
      <div class="flex-fill"></div>
      <button mat-raised-button routerLink="/main/upgrade">upgrade</button>
    </div>
    <div class="flex-content">
      <router-outlet></router-outlet>
    </div>
    <div class="flex-footer">
      <div class="flex-row">
        <span>Copyright Marten Software LLC</span>
        <span class="flex-fill"></span>
        <a routerLink="/main/terms">terms and privacy</a>
      </div>
      
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>